import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import axios from 'axios';
import config from '../config';

const SettingForm = ({ setting, onSave, onCancel, auth }) => {
  const [fileID, setFileID] = useState(setting ? setting.fileID : '');
  const [param, setParam] = useState(setting ? setting.param : '');
  const [value, setValue] = useState(setting ? setting.value : '');
  const [hash, setHash] = useState(setting ? setting.hash : '');
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/files`, {
          auth: auth,
        });
        setFiles(response.data.list);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };
    fetchFiles();
  }, [auth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = { fileID, param, value, hash };
      if (setting) {
        await axios.put(`${config.API_BASE_URL}/setting/${setting.id}`, data, {
          auth: auth,
        });
      } else {
        await axios.post(`${config.API_BASE_URL}/setting`, data, {
          auth: auth,
        });
      }
      onSave();
    } catch (error) {
      console.error('Error saving setting:', error);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          maxWidth: '500px',
          margin: '0 auto',
          padding: '20px',
          border: '1px solid #ccc',
          borderRadius: '8px',
        }}
      >
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal">
            <InputLabel>File</InputLabel>
            <Select value={fileID} onChange={(e) => setFileID(e.target.value)}>
              {files.map((file) => (
                <MenuItem key={file.id} value={file.id}>
                  {file.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Param"
            value={param}
            onChange={(e) => setParam(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Hash"
            value={hash}
            onChange={(e) => setHash(e.target.value)}
          />
          <Box display="flex" alignItems="center" gap="20px">
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button variant="contained" onClick={onCancel}>
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default SettingForm;

