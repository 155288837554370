import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Alert, Box } from '@mui/material';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loginSuccess = await onLogin(username, password);
    if (!loginSuccess) {
      setError('Invalid username or password');
    }
  };

  return (
    <Container>
      <Box
        sx={{
          width: 300,
          margin: 'auto',
          padding: 2,
          border: '1px solid #ccc',
          borderRadius: 4,
          boxShadow: 1,
          mt: 5,
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Login
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Login;

