import React, { useState } from 'react';
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

const EntityList = ({ projects, files, settings, onEdit, onDelete, onNew }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState({ type: '', id: null });

  const handleOpenDialog = (type, id) => {
    setEntityToDelete({ type, id });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEntityToDelete({ type: '', id: null });
  };

  const handleConfirmDelete = () => {
    onDelete(entityToDelete.type, entityToDelete.id);
    handleCloseDialog();
  };

  return (
    <Container>
      <Typography variant="h4">Entities</Typography>
      <Box display="flex" alignItems="center" gap="20px" mb={2}>
        <Button variant="contained" color="primary" onClick={() => onNew('project')}>
          New Project
        </Button>
        <Button variant="contained" color="primary" onClick={() => onNew('file')}>
          New File
        </Button>
        <Button variant="contained" color="primary" onClick={() => onNew('setting')}>
          New Setting
        </Button>
      </Box>

      <div style={{ backgroundColor: '#f0f8ff', padding: '10px', marginTop: '10px' }}>
        <Typography variant="h5">Projects</Typography>
        <List>
          {projects.map((project) => (
            <ListItem key={project.id}>
              <ListItemText primary={project.name} secondary={project.url} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => onEdit('project', project)}>
                  <Edit />
                </IconButton>
                <IconButton edge="end" onClick={() => handleOpenDialog('project', project.id)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>

      <div style={{ backgroundColor: '#faebd7', padding: '10px', marginTop: '10px' }}>
        <Typography variant="h5">Files</Typography>
        <List>
          {files.map((file) => (
            <ListItem key={file.id}>
              <ListItemText primary={file.name} secondary={`${file.format} - ${file.filePath}`} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => onEdit('file', file)}>
                  <Edit />
                </IconButton>
                <IconButton edge="end" onClick={() => handleOpenDialog('file', file.id)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>

      <div style={{ backgroundColor: '#f5f5dc', padding: '10px', marginTop: '10px' }}>
        <Typography variant="h5">Settings</Typography>
        <List>
          {settings.map((setting) => (
            <ListItem key={setting.id}>
              <ListItemText primary={setting.param} secondary={setting.value} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => onEdit('setting', setting)}>
                  <Edit />
                </IconButton>
                <IconButton edge="end" onClick={() => handleOpenDialog('setting', setting.id)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this entity?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EntityList;

