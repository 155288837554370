import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, MenuItem, Select, InputLabel, FormControl, Box } from '@mui/material';
import axios from 'axios';
import config from '../config';

const FileForm = ({ file, onSave, onCancel, auth }) => {
  const [format, setFormat] = useState(file ? file.format : 'json');
  const [filePath, setFilePath] = useState(file ? file.filePath : '');
  const [filePath2, setFilePath2] = useState(file ? file.filePath2 : '');
  const [name, setName] = useState(file ? file.name : '');
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/files`, {
          auth: auth,
        });
        setFiles(response.data.list);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };
    fetchFiles();
  }, [auth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = { format, filePath, filePath2, name };
      if (file) {
        await axios.put(`${config.API_BASE_URL}/file/${file.id}`, data, {
          auth: auth,
        });
      } else {
        await axios.post(`${config.API_BASE_URL}/file`, data, {
          auth: auth,
        });
      }
      onSave();
    } catch (error) {
      console.error('Error saving file:', error);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          maxWidth: '500px',
          margin: '0 auto',
          padding: '20px',
          border: '1px solid #ccc',
          borderRadius: '8px',
        }}
      >
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Format</InputLabel>
            <Select value={format} onChange={(e) => setFormat(e.target.value)}>
              <MenuItem value="json">JSON</MenuItem>
              <MenuItem value="yaml">YAML</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="File Path"
            value={filePath}
            onChange={(e) => setFilePath(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="File Path 2"
            value={filePath2}
            onChange={(e) => setFilePath2(e.target.value)}
          />
          <Box display="flex" alignItems="center" gap="20px">
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button variant="contained" onClick={onCancel}>
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default FileForm;

